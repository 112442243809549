@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-x: hidden;
  white-space: pre-line;
  position: absolute;
  top: 0;
  left: 0;
}
.block-scroll {
  overflow: hidden;
}
button {
  cursor: pointer;
}
a,
a:visited,
a:hover,
a:active {
  text-decoration: unset;
  color: unset;
}
html {
  -ms-overflow-style: none;
  scrollbar-width: none;
  --bg-color: #fff; /*#e5e5e5;*/
  --font: "Roboto", sans-serif;
  --hero-title: 900 70px var(--font);
  --title-1: 900 36px var(--font);
  --title-2: 700 24px var(--font);
  --title-3: 700 20px var(--font);
  --subtitle-1: 300 24px var(--font);
  --subtitle-2: 400 20px var(--font);
  /* --subtitle-3: 18px; */
  --header: 400 18px var(--font);
  --text-high: 500 16px var(--font);
  --text-medium: 400 18px var(--font);
  --text: 400 14px var(--font);
  /* --text-low: 12px; */

  --false-black: #282828;
  color: var(--false-black);
  /* Don't forget to change this value in svg files if you change it here*/
  --green-enjoy: #b3cf42;

  /* default "padding" of the page, used if needed by the children of home */
  --margin: max(80px, 5.6vw);
}
html::-webkit-scrollbar {
  display: none;
}
.noscrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.noscrollbar::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1366px) {
  html {
    --margin: max(40px, 4.5vw);
    --text-medium: 400 16px var(--font);
  }
}
@media screen and (max-width: 780px) {
  html {
    --margin: max(40px, 4.5vw);
  }
}
@media (min-width: 451px) and (max-width: 1366px) {
  html {
    --hero-title: 900 max(5.12vw, 50px) var(--font);
    --header: 400 max(1.6vw, 16px) var(--font);
    --margin: 40px;
  }
}
@media screen and (max-width: 450px), (max-height: 640px) {
  html {
    --hero-title: 900 48px var(--font);
    --title-1: 900 24px var(--font);
    --title-2: 700 18px var(--font);
    --subtitle-1: 300 18px var(--font);
    --subtitle-2: 400 16px var(--font);
    --header: 400 16px var(--font);

    --title-3: 700 20px var(--font);
    /* --subtitle-3: 18px; */
    --text-high: 500 16px var(--font);
    --text: 400 14px var(--font);
    /* --text-low: 12px; */
  }
  body {
    overflow-x: hidden;
  }
}
@media screen and (max-width: 360px) {
  html {
    --hero-title: 900 36px var(--font);
    --title-1: 900 22px var(--font);
    --title-3: 700 18px var(--font);
    --subtitle-1: 300 16px var(--font);
    --subtitle-2: 400 14px var(--font);

    --title-2: 700 18px var(--font);
    --header: 400 16px var(--font);

    /* --subtitle-3: 18px; */
    --text-high: 500 16px var(--font);
    --text: 400 14px var(--font);
    /* --text-low: 12px; */
  }
  body {
    overflow-x: hidden;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* .lazy {
  background-color: rgb(220, 220, 220) !important;
  animation: tnjlazybg 3s ease-in-out infinite both;
  opacity: 1 !important;
  color: transparent !important;
} */
.lazy  * {
  color: transparent !important;
}
.lazy:nth-child(n+1) {
  animation-delay: 0.2s;
}
.lazy:nth-child(n+2) {
  animation-delay: 0.35s;
}
.lazy:nth-child(n+3) {
  animation-delay: 0.5s;
}
.lazy:nth-child(n+4) {
  animation-delay: 0.65s;
}
.lazy:nth-child(n+5) {
  animation-delay: 0.8s;
}

/* @keyframes tnjlazybg {
  0%,
  100% {
    background-color: rgb(100, 100, 100) !important;
  }
  50% {
    background-color: rgb(255, 255, 255) !important;
  }
} */
.lazy {
  background: linear-gradient(68deg, rgb(220, 220, 220) 30%, rgb(235, 235, 235) 50%, rgb(220, 220, 220) 70%);
  background-size: 400% 100%;
  animation: gradient 8s linear infinite both;
  opacity: 1 !important;
  color: transparent !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 400% 100%;
  }
}
#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
