.calendar-confirm.big-button {
    padding: 0px 16px;
    position: absolute;
    right: 8px;
    bottom: 8px;
    height: 36px;
    font: var(--text-high);
}
.home-search {
    .loupe {
        height: 100%;
        &.highlighted {
            border-radius: 20;
            background-color: var(--green-enjoy);
            margin: 20px;
            height: calc(100% - 40px);
            width:max(56px, calc(7vw - 44px));
        }
    }
}
