.search-input {
    display: flex;
    position: relative;
    > img {
        height: 100%;
        position: absolute;
        top: 0;
        right: 8px;
        opacity: 0.8;
        border-radius: 50%;
        cursor:pointer;
        box-sizing: border-box;
        padding: 3%;
    }
}
