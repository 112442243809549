.home-search {
    $top: -50px; //unquote("min(-3.5vw, -50px)");
    $height: 100px; //unquote("max(100px, 7vw)");
    margin-left: var(--margin);
    margin-right: var(--margin);
    position: relative;
    border-radius: 10px;
    top: $top;
    background-color: var(--bg-color); /*#f3f3f3;*/
    // width: 75vw;
    padding: 0;
    height: $height;
    // border: 2px solid #f3f3f3;
    box-shadow: 0px 21px 29px rgba(220, 220, 220, 0.25);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    z-index: 2;
    button.loupe {
        flex-shrink: 0;
        padding: 0;
        outline: none;
        border: none;
        border-radius: 6px;
        // margin-left: 80px;
        // margin-bottom: 2px;
        justify-self: flex-end;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        // background-color: white;
        background-color: var(--green-enjoy);
        padding: 22px;
        margin: 18px;
        box-sizing: border-box;
        span {
            display: none;
        }
        img.dark {
            display: none;
        }
        &.main {
            margin: 0;
            border-radius: 10px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            height: 100%;
            width: unset;
            margin: 0;
            padding: 0 38px 0 64px;
            transition-duration: 0.2s;
            span {
                display: inline;
                color: white;
                margin-right: 26px;
                font-family: var(--font);
                font-style: normal;
                font-weight: normal;
                font-size: 22px;
                line-height: 100.69%;
                transition-duration: 0.2s;
            }
            &:hover {
                transition-duration: 0.2s;
                background-color: var(--bg-color);
                span {
                    transition-duration: 0.2s;
                    color: var(--green-enjoy);
                }
                img.light {
                    display: none;
                }
                img.dark {
                    display: block;
                }
            }
        }
    }
}

.filters-popup {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start; //space-around;

    position: absolute;
    height: 385px;
    top: -409px;
    // left: 25%;
    box-shadow: 0px 21px 29px rgba(220, 220, 220, 0.25);
    background-color: var(--bg-color);
    border-radius: 10px;
    z-index: 100;
    transition: all 0.4s;
    left: 0;
    &.hidden {
        display: none;
    }
}

.filters {
    width: 100%;
    height: 100%;
    display: grid;
    border-radius: 10px;
    grid-template-columns: repeat(3, 1fr) 1.4fr;
    align-items: center;
    justify-items: center;
    .filters-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.current {
            position: relative;
            z-index: 2;
            box-shadow: -2px 4px 28px rgba(190, 190, 190, 0.25);
            border-radius: 14px;
            label {
                color: var(--green-enjoy) !important;
            }
        }
        & > div {
            // height: 60px;
            display: flex;
            flex-direction: column;
            justify-self: center;
            width: 100%;
            box-sizing: border-box;
            padding: 0 0 0 min(50px, 5vw);
            .label {
                // display: grid;
                margin-top: 0px;
                // font: var(--subtitle-1);
                font: var(--text);
                font-weight: 13px;
                color: #767e86;
                letter-spacing: 0.1em;
                height: 14px;
                cursor: pointer;
            }
            > span {
                display: flex;
                align-items: center;
                width: 100%;
                .input {
                    height: 100%;
                    border: 0px;
                    outline: none;
                    height: 28px;
                    padding: 0;
                    opacity: 1;
                    color: #1c1c1c;
                    // margin: 0 0 8px;
                    font: var(--title-1);
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    width: 100%;
                    white-space: nowrap;
                    &::placeholder {
                        color: #1c1c1c;
                        opacity: 1;
                    }
                }
                > img {
                    height: 16px;
                    width: 16px;
                    margin-right: 28px;
                    transform: rotate(180deg);
                }
            }
        }
    }

    img:hover {
        cursor: pointer;
    }
}
@media screen and (max-width: 640px) {
    .home-search {
        width: min-content;
    }
}
@media (max-width: 450px) {
    .home-search {
        --trans-duration: 0.8s;
        width: 100%;
        top: -30px;
        height: 30px;
        border: none;
        padding: 0;
        border-radius: 0;
        background: none;
        box-shadow: none;
        overflow: visible;
        // transition-duration: var(--trans-duration);
        margin-left: 0;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 3;
        position: relative;
        .home-search-bar {
            z-index: 4;
            height: 60px !important;
            padding: 0;
            justify-self: center;
            border-radius: 8px;
            box-sizing: content-box;
            display: flex;
            overflow: hidden;
            background-color: var(--bg-color);
            box-shadow: 0px 21px 29px rgba(220, 220, 220, 0.25);
            position: absolute;
            width: calc(100vw - 52px);
            max-width: 500px;
            button {
                margin-left: 0;
                height: 60px;
                width: 60px;
                margin: 0;
                background-color: var(--bg-color);
                img {
                    background-color: var(--bg-color);
                }
            }
            .mobile {
                display: flex;
                align-items: center;
                font: var(--subtitle-1);
                outline: none;
                border: none;
                width: 100%;
                padding-left: 16px;
                color: #9d9d9d;
                font-size: 18px;
            }
        }
        .back-arrow {
            opacity: 0;
            position: absolute;
            top: -65px;
            left: 26px;
            height: 20px;
            width: 20px;
            align-self: flex-start;
            background: none;
            border: none;
            z-index: 3;
        }
        .home-search-lower {
            z-index: 3;
            margin-top: 30px;
            background-color: var(--bg-color);
            width: 100%;
            // height:0;
            height: calc(100% - 30px);
        }
    }
    .home-search.open {
        height: calc(100vh - 150px + 60px);
        transition-duration: 0.8s;
        .back-arrow {
            transition-delay: 1s;
            transition-duration: 0.2s;
            opacity: 1;
        }
        .home-search-lower {
        }
    }
    // .home-hero, .home-top-bar-simple{
    //     transition-duration: .8s;
    //     > *:not(.home-hero-img) {
    //         transition-duration: 0.6s;
    //         transition-delay: .2s;
    //     }
    // }
    .openMenu {
        height: 150px !important;
        z-index: 0 !important;
        transition-duration: 0.8s;
        > *:not(.home-hero-img) {
            transition-duration: 0.6s;
            opacity: 0;
        }
        .home-hero-img {
            height: 73vh;
        }
    }
}
