.big-button {
    flex-shrink: 0;
    justify-self: flex-end;
    padding: 16px 36px;
    background-color: var(--green-enjoy);
    color: white;
    font: var(--header);
    font-weight: 700 !important;
    border: none;
    outline: none;
    border-radius: 6px;
    box-sizing: border-box;
    border: 3px solid var(--green-enjoy);
        cursor: pointer;
    &.light:not(:active),
    &:active:not(.light):not(.white):not(.grey) {
        color: var(--green-enjoy);
        background-color: var(--bg-color);
    }
    &.white {
        color: var(--green-enjoy);
        background-color: var(--bg-color);
        border-color: var(--bg-color);
        &:hover {
            background-color: var(--green-enjoy);
            border-color: var(--green-enjoy);
            color: var(--bg-color);
        }
        &:active {
            color: var(--bg-color);
            background: none;
        }
    }
    &.grey {
        color: var(--bg-color);
        background-color: lightgrey;
        border-color: lightgrey;
        &:active {
            color: lightgrey;
            background: var(--bg-color);
        }
    }
}
