.home-search {
    .home-search-lower .search-mobile .top-search-box{
        grid-template-columns: 1fr;
        margin-top:54px;
    }
    .home-search-upper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0 28px;
        box-sizing: border-box;
        p {
            font: var(--title-2);
            color: white;
            font-size: 12px;
            font-weight: 400;
            text-decoration: underline;
            line-height: 16, 98px;
            text-align: center;
            width: 100%;
            flex-grow:1;
            // position: absolute;
            // left: 10%;
            margin: 0 56px 0 28px;
            box-sizing: border-box;
        }
        .back-arrow {
            flex-shrink:0;
            background-color: transparent;
            background-repeat: no-repeat;
            border: none;
            cursor: pointer;
            overflow: hidden;
            outline: none;
            padding: 0;
            

            img {
                flex-shrink: 0;
                // position: absolute;
                // top: 95px;
                // left: 30px;
                width: 20px;
            }
        }
    }
}
