.star-rate-component {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
.star {
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    width: 24px;
    height: 24px;
    background-color: #e8f1c6;
    > div {
        width: 100%;
        height: 100%;
        background-color: var(--green-enjoy);
        opacity: 0;
    }
    &.filled > div {
        opacity: 1;
    }
}
