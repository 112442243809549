.search-calendar {
    --calendar-month-width: 280px;
    // margin-top: 16px;
    align-self: center;
    display: flex;
    height: calc(var(--calendar-month-width) + 24px); //calc(100% - 60px);
    width: calc(2 * var(--calendar-month-width) + 48px + 80px);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    > *:first-child.day-label {
        display: none;
    }
    .day-label {
        flex-shrink: 0;
        width: 100vw;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(7, calc(var(--calendar-month-width) * 0.1));
        column-gap: calc(var(--calendar-month-width) * 0.05);
        border-bottom: 1px solid lightgrey;
        text-align: center;
        padding: 0 calc(50vw - (var(--calendar-month-width) / 2)) 8px;
        color: rgb(113, 113, 113);
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 16px !important;
    }
    .arrow {
        position: absolute;
        top: 14px;
        z-index: 2;
        &.left {
            left: 48px;
        }
        &.right {
            right: 48px;
        }
    }
    .search-calendar-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        overflow: clip visible;
        flex-shrink: 1;
        box-sizing: border-box;
        font-size: 14px !important;
        line-height: 20px !important;
        font-weight: 500 !important;
        padding: 0 24px;
        h3 {
            text-align: center;
            margin: 20px 0;
            font-size: 18px !important;
            line-height: 22px !important;
        }
        > div {
            margin: 0 32px 0 16px;
            transition-duration: 0.4s;
            &:last-child {
                margin: 0 16px;
                margin-bottom: 180px;
            }
        }
        .calendar-month {
            width: var(--calendar-month-width);
            display: grid;
            grid-template-columns: repeat(7, 1fr); //calc(var(--calendar-month-width) * 0.1));
            grid-template-rows: repeat(6, calc(var(--calendar-month-width) * 0.1));
            column-gap: 0px;
            row-gap: calc(var(--calendar-month-width) * 0.05);
            justify-items: center;
            > div {
                padding: 0 calc(var(--calendar-month-width) * 0.025);
                box-sizing: border-box;
                &.past {
                    opacity: 0.2;
                }
                &.all {
                    background-color: var(--green-enjoy);
                    border-radius: 50%;
                    margin-left: calc(var(--calendar-month-width) * 0.025);
                    margin-right: calc(var(--calendar-month-width) * 0.025);
                    padding: 0;
                    align-self: center;
                    width: calc(100% - calc(var(--calendar-month-width) * 0.0375));
                    > div {
                        margin: 4px;
                        width: calc(100% - 8px) !important;
                        height: calc(100% - 8px) !important;
                        color: white;
                        // background-color: #abc730;
                    }
                }
                &.start,
                &.end,
                &.in {
                    background-color: var(--green-enjoy);
                    color: white;
                    &.start {
                        margin-left: calc(var(--calendar-month-width) * 0.025);
                        padding: 0;
                        border-top-left-radius: calc(var(--calendar-month-width) * 0.05);
                        border-bottom-left-radius: calc(var(--calendar-month-width) * 0.05);
                        > div {
                            margin-right: calc(var(--calendar-month-width) * 0.0375);
                            background-color: #abc730;
                        }
                    }
                    &.end {
                        margin-right: calc(var(--calendar-month-width) * 0.025);
                        padding: 0;
                        border-top-right-radius: calc(var(--calendar-month-width) * 0.05);
                        border-bottom-right-radius: calc(var(--calendar-month-width) * 0.05);
                        > div {
                            margin-left: calc(var(--calendar-month-width) * 0.0375);
                            background-color: #abc730;
                        }
                    }
                }
                &,
                > div {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
                > div {
                    border-radius: 50%;
                }
            }
        }
    }
    .search-calendar-foot {
        display: none;
    }
}
.m-calendar.search-calendar {
    --calendar-month-width: 90vmin;
    margin-top: 0;
    width: unset;
    height: calc(100% - 60px);
    padding: unset;
    position: static;
    .arrow {
        display: none;
    }
    .search-calendar-box {
        font-size: 16px !important;
    }
    .search-calendar-box {
        display: block;
        width: unset;
        padding-top: 60px;
        overflow: hidden scroll;
        > div {
            margin: unset;
        }
    }
    .search-calendar-foot {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100vw;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        box-shadow: 0px -10px 29px rgba(220, 220, 220, 0.25);
        padding: 16px;
        box-sizing: border-box;
        > button {
            height: 50px;
            padding: 0 32px;
            &.light {
                border-color: white;
                color: grey;
                font-weight: 500 !important;
            }
        }
    }
}
