footer {
    background-color: var(--bg-color);
    position: absolute;
    display: flex;
    flex-direction: column;
    > div {
        // display: grid;
        // grid-template-columns: repeat(6, 1fr);
        // justify-items: stretch;
        // column-gap: var(--margin);
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 56px var(--margin) 56px 150px;
        box-sizing: border-box;
        margin-bottom: 59px;
        .panel-box {
            flex-shrink: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 33px;
            width: calc(50vw - 70px);
            .panel {
                // display: none;
                margin: 16px 0 0;
                h5 {
                    font: var(--title-3);
                    font-size: 18px;
                    margin: 0 0 16px;
                }
                ul {
                    font: var(--subtitle-2);
                    font-size: 18px;
                    font-weight: 700;
                    padding: 0;
                    li {
                        margin-top: 10px;
                        list-style: none;
                        font: var(--subtitle-2);
                        font-size: 18px;
                        font-weight: 300;
                        cursor: pointer;
                    }
                }
            }
        }
        .grid-container {
            // height: 100%;
            width: 100%;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            max-width: 450px;

            .newsletter {
                border-radius: 8px;
                padding: 16px 0 24px;
                box-sizing: border-box;
                width: 100%;
                margin-bottom: 26px;

                h4 {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 21px;
                }
                h6 {
                    margin: 16px 0 48px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 31, 26px;
                    color: #999999;
                    width: 67%;
                }
                > div {
                    margin: 16px 0 0;
                    height: max-content;
                    width: 100%;
                    display: flex;
                    background-color: #fafafa;
                    input {
                        width: 100%;
                        box-sizing: border-box;
                        padding-left: 8px;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        height: 73px;
                        padding-left: 38px;
                        padding-right: 8px;
                        border: none;
                        outline: none;
                        background: none;
                        font: var(--font);
                        &,
                        &::placeholder {
                            color: #8c97ac;
                        }
                        // &::placeholder {
                        //     margin-left: 87px;
                        // }
                    }
                    .button {
                        outline: none;
                        border: none;
                        height: 100%;
                        flex-shrink: 0;
                        background-color: var(--green-enjoy);
                        border-left: none;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        white-space: nowrap;
                        box-sizing: border-box;
                        padding: 27px 46px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 19px;
                        color: white;
                    }
                }
                .newsletter-log-message {
                    margin-top: 8px;
                    &.failed {
                        color: red;
                    }
                }
            }
            .social-network {
                width: 100%;
                max-width: 500px;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 58px;
                column-gap: 14px;
                > div {
                    cursor: pointer;
                    padding: 16px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    background-color: #fafafa;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    p {
        // border-top: 1px solid lightgrey;
        margin: 0;
        padding: 60px var(--margin) 60px 150px;
        font: var(--subtitle-2);
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #7b7b7b;
        width: 100vw;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        > span {
            &.language-switch {
                > span {
                    color: #7b7b7b;

                    margin-right: 16px;
                    cursor: pointer;
                    letter-spacing: 0.4px;
                    &.active {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    footer {
        > div {
            padding-left: var(--margin);
        }
        > p {
            padding-left: var(--margin);
        }
    }
}
// @media screen and (max-width: 1000px) {
//     footer > div {
//         align-items: center;
//         .grid-container {
//             column-gap: 8px;
//             // min-height: 40vh;
//             row-gap: 32px;
//             margin-bottom: 40px;
//             grid-template-columns: 1fr;
//             max-width: 500px;
//             .newsletter {
//                 width: 100%;
//                 flex-shrink: 1;
//             }
//         }
//     }
// }

@media screen and (max-width: 1000px) {
    footer {
        > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-bottom: 0;
            align-items: center;

            .grid-container {
                order: 1;
                column-gap: 8px;
                // min-height: 40vh;
                row-gap: 32px;
                grid-template-columns: 1fr;
                max-width: 511px;
                margin-bottom: 64px;
                > .newsletter {
                    width: 100%;
                    flex-shrink: 1;
                    margin: 0;
                    padding: 0;
                }

                > .social-network {
                    justify-content: space-between;
                    // padding: 0 24px;
                    box-sizing: border-box;
                    > img {
                        margin: 0 !important;
                    }
                }
            }
            .panel-box {
                order: 2;
                // width:100%;
                // box-sizing: border-box;
                width: unset;
                align-self: center;
                margin-bottom: 40px;
                .panel {
                    width: auto; //20vw;
                }
                // width: calc(100vw - var(--margin) - var(--margin));
                // grid-template-columns: 1fr;
                // margin-bottom: 24px;
                // .panel {
                //     ul {
                //         display: grid;
                //         grid-template-columns: 1fr 1fr 1fr 1fr;
                //         grid-auto-flow: row;
                //     }
                // }
            }
        }
        > p {
            display: flex;
            flex-direction: column;
            span.language-switch {
                margin-bottom: 16px;
            }
        }
    }
}
// @media screen and (max-width: 640px) {
//     footer {
//         > div {
//             .panel-box {
//                 grid-template-columns: 1fr 1fr;
//             }
//         }
//     }
// }
@media screen and (max-width: 450px) {
    footer {
        > div {
            margin-bottom: 0;
            .panel-box {
                order: 2;
                width: calc(100vw - var(--margin) - var(--margin));
                box-sizing: border-box;
                grid-template-columns: 1fr;
                margin-bottom: 24px;
                .panel {
                    ul {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-auto-flow: row;
                    }
                }
            }
        }
    }
}
