.reset-topbar {
    box-shadow: 0px 21px 29px rgba(220, 220, 220, 0.25);
}
.resetPage {
    margin: 144px 0 0;
    min-height: calc(100vh - 144px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15vh var(--margin) 0;
    box-sizing: border-box;
    .resetBox {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 400px;
        // min-width: 40vw;
        padding: 40px;
        box-shadow: 0px 5px 29px rgba(215, 215, 215, 0.45);
        height: min-content;
        border-radius: 10px;
        h2 {
            margin: 0 0 12px;
        }
        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            .password-input {
                height: 60px;
                border: 1px solid #ddd;
                outline: none;
                margin: 14px 0;
                border-radius: 6px;
                padding: 4px 4px 4px 8px;
                width: 100%;
                box-sizing: border-box;
                &,
                & > input {
                    color: var(--false-black);
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    background: none !important;
                    font-weight: 400;
                    font-family: var(--font);
                }
            }
            .submit-button {
                height: 60px;
                margin: 20px 0 0;
                background-color: var(--green-enjoy);
                padding: 12px 36px;
                width: 100%;
                border-radius: 10px;
                border: none;
                color: var(--bg-color);
                font: var(--header);
                font-weight: 700 !important;
            }
        }
    }
    .reset-logmessage {
        margin-top: 16px;
        color: red;
        &.success {
            color: revert;
        }
    }
}
@media screen and (max-width: 480px) {
    .reset-topbar {
        margin-top: 0;
        align-items: center;
        height: 104px;
        *:not(.tnj-logo) {
            display: none;
        }
    }
    .resetPage {
        margin-top:104px;
        padding: 3vh 0 0;
        min-height: calc(100vh - 104px);
        .resetBox {
            box-shadow: none;
        }
    }
}
