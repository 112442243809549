.search-mobile {
    display: flex;
    padding: 0 28px;
    height: 100%;
    width: 100%;
    overflow: hidden scroll;
    flex-direction: column;
    box-sizing: border-box;
    h3.section-header {
        font: var(--title-1);
        font-weight: 700;
        font-size: 14px;
        line-height: 14.1px;
        color: #888888;
        letter-spacing: 0.1rem;
        margin: 26px 0 0;
    }
    h4 {
        font: var(--title-1);
        font-size: 18px;
        font-weight: 400;
        color: #484848;
        line-height: 18.12px;
    }
    h6 {
        font-size: 14px;
        line-height: 14.1px;
        font-weight: 400;
        color: #545454;
    }
    .top-search-box {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        .top-search-item {
            // position: sticky;
            // top: 0;
            padding-top: 0;
        }
    }
    .section-content {
        color: #888888;
        // height: 100%;
        // overflow: hidden scroll;
        margin-left: -8px;
        margin-right: -8px;
        .search-item:last-child {
            margin-bottom: 100px;
        }
    }
    .search-item {
        margin: 0 -28px;
        padding: 22px 26px 0;
        // margin-bottom: 16px;
        display: grid;
        // grid-row: 1;
        grid-template-columns: 60px auto;
        grid-template-rows: repeat(2, 28px);
        row-gap: 4px;
        column-gap: 24px;
        // background-color: white;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
            background-color: #f7f7f7;
        }
        h4 {
            color: #484848;
        }
        > div,
        button {
            position: relative;
            grid-area: 1/1/3/2;
            height: 60px;
            width: 60px;
            padding: 0;
            background: url("../../../assets/localisation.svg") no-repeat, rgba(179, 207, 66, 0.2);
            background-position: center;
            border: none;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            img {
                position: absolute;
                top: 0;
                left: 0;
                object-position: center;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .checked {
                box-sizing: border-box;
                background-color: rgba(0, 0, 0, 0.3);
                padding: 16px;
            }
            .geoloc-city-loading {
                width: 100%;
                height: 100%;
                padding: 8px;
                box-sizing: border-box;
                opacity: 0;
                transition: transform 100s;
            }
        }
        .category {
            grid-area: 1/1/3/2;
            height: 60px;
            width: 60px;
            padding: 0;
            background: url("../../../assets/dice.svg") no-repeat;
            background-position: top 50% right 50%;
            border-color: rgba(90, 142, 203, 0);
            background-color: rgba(90, 142, 203, 0.2);
        }
        h4 {
            // grid-column: 2;
            margin: 0;
            margin-top: 10px;
        }
        h6 {
            // grid-column: 2;
            opacity: .6;
            margin: 0;
        }
    }
}

@media (min-width: 450px) {
    .search-mobile {
        // width: 100%;
        .top-search-item {
            // padding-top: 20px ;
            margin: 12px -8px -8px !important;
            padding: 8px !important;
            box-sizing: border-box;
        }
        h3.section-header {
            margin-bottom: 24px;
        }
        .section-content {
            // width: 600px;
            display: grid;
            column-gap: 0px;
            row-gap: 0px;
            &:not(.one-column) {
                grid-template-columns: repeat(3, 1fr);
                .search-item:nth-last-child(-n + 3) {
                    margin-bottom: 20px;
                }
            }
            .search-item {
                width: 100%;
                margin: 0;
                padding: 8px;
                box-sizing: border-box;
                &:last-child {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .search-mobile {
        animation: opacityIn 0.4s forwards;
        @keyframes opacityIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        .top-search-item {
        }
    }
}
