.popup-overlay.menu-popup {
    display: block;
    .popup-content {
        background-color: var(--bg-color);
        position: absolute;
        top: 0;
        bottom: 0;
        left: -100%;
        width: 75vw;
        // border-radius: 0 5px 5px 0;
        padding: 5vh var(--margin);
        box-sizing: border-box;
        animation-duration: 0.6s;
        animation-name: slideinMenu;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        @keyframes slideinMenu {
            from {
                left: -100%;
            }

            to {
                left: 0;
            }
        }
        h2 {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            .menu-close {
                height: 20px;
            }
        }
        ul{
            padding:0;
            display: flex;
            flex-direction: column;
            > * {
                padding: 10px 0;
                cursor:pointer;
            }
        }
    }
}
