header {
    width: 100vw;
    // min-height: 104px;
    // height: 104px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 20px var(--margin);
    box-sizing: border-box;
    z-index: 50;
    background-color: var(--bg-color);
    max-height: calc(12.5vh + 40px);
    > * {
        max-height: 64px;
    }
    .mobile-icons {
        display: none;
    }
    .filler {
        width: 100%;
        flex-shrink: 1;
        text-align: right;
        color: white;
        text-decoration: underline;
        a {
            margin-right: 19px;
        }
    }
    &.fixed {
        position: fixed;
    }
    &.transparent {
        background-color: transparent;
    }
    .tnj-logo {
        height: 64px;
        padding: 8px 8px 8px 0;
        box-sizing: border-box;
        // width: 100%;
        object-fit: contain;
        object-position: left;
        &:active {
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 5px;
        }
        &:hover {
            cursor: pointer;
        }
    }
    nav.landing-top {
        flex-shrink: 0;
        // width: 40vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 28px;
        justify-self: flex-start;
        height: 50px;
        a {
            margin-left: 40px;
            color: white;
            font: var(--header);
            font-size: 18px;
            text-decoration: none;
            &.current {
                font-weight: 500;
            }
            &:visited {
                color: white;
            }
            &:focus-visible {
                outline: none;
            }
        }
    }
    button.landing-top {
        padding: 22px 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 18px;
    }
    .top-filter-bar-user {
        width: calc(40vw - var(--margin));
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        .big-button {
            padding: 12px 36px;
        }
    }
    .top-filter-bar-popup {
        top: 100px;
        // border: 1px solid lightgrey;
        max-width: 100vw;
        width: 688px;
    }
    .top-filter-bar-wrapper {
        margin-right: 40px;
        width: 35vw;
        min-width: 440px;
        // height: 100%;
        height: 52px;
        flex-shrink: 0;
        position: relative;
    }
    .top-filter-bar {
        height: 100%;
        width: 100%;
        border: 1px solid #dedede;
        border-radius: 8px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 2px;
        box-sizing: border-box;
        position: relative;
        .filters-box {
            border-radius: 0 !important;
            > div {
                width: 100%;
                padding: 0;
                & > span {
                    height: 100%;
                    width: 100%;
                    margin: 2px 0;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    .input {
                        width: 100%;
                        text-align: center;
                        margin: 0;
                        box-sizing: border-box;
                        font: var(--header);
                        font-size: 16px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: var(--bg-color);
                    }
                    img {
                        margin: 0;
                        position: absolute;
                        right: 20px;
                        width: 8px;
                        transform: rotate(0deg);
                        z-index: 1;
                    }
                }
            }
        }
        .loupe,
        :not(.search-input) :is(button, label) {
            display: none !important;
        }
        // .filters-box:not(:first-child) {
        //     border-left: 1px solid rgba(211, 211, 211, 0.562);
        // }
        .filters-box:first-child .search-input {
            width: 99%;
            img {
                display: block !important;
            }
            input {
                transition-delay: 0.2s;
                padding-left: 0;
                text-align: center;
                transition: text-align 0s 0s;
                &:focus {
                    text-align: left;
                    padding-left: 16px;
                }
            }
        }
    }
    .start-search {
        border: 1px solid lightgrey;
        border-radius: 10px;
        padding: 0;
        margin-left: 40px;
        flex-shrink: 1;
        // min-width: 300px;
        width: 100%;
        padding: 0;
        // display: flex;
        // align-items: center;
        position: relative;
        // margin-right: 100px;
        overflow: hidden;
        cursor: pointer;
        height: 48px;
        // box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 4px 12px !important;
        // transition: box-shadow 0.2s ease 0s !important;
        // &:hover {
        // box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px !important;
        // }
        .input {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            margin: 0;
            background: none;
            border: none;
            padding: 0 0 0 16px;
            display: flex;
            align-items: center;
            outline: none;
            font: var(--header);
            font-size: 18px;
            line-height: 21px;
            color: #464646;
            font-weight: 400;
        }
        img {
            height: 16px;
            width: 16px;
            position: absolute;
            right: 16px;
            top: calc(50% - 8px);
        }
    }
    nav.landing-top.with-search a {
        color: var(--false-black);
        font-size: 18px;
    }
}

@media screen and (max-width: 950px) {
    .start-search {
        display: none !important;
    }
}
@media screen and (max-width: 1366px) {
    header nav {
        // min-width: 400px;
        margin-right: unquote("min(3.8vw, 56px)");
        margin-left: 8px;
    }
    header .big-button {
        padding: 16px unquote("min(2vw, 32px)");
    }
}
@media screen and (max-width: 1280px) {
    header {
        .top-filter-bar-user {
            width: 100%;
            min-width: 150px;
            flex-shrink: 1;
        }
        .filler {
            flex-shrink: 1.2;
        }
    }
}
@media screen and (max-width: 640px) {
    header {
        display: flex;
        // flex-direction: column;
        // align-items: center;
        justify-content: space-between;
        // max-height: unset;
        // height: unset;
        justify-content: center;
        > .filler,
        > .landing-top {
            display: none !important;
        }
    }
}
@media screen and (max-width: 450px) {
    header {
        margin-top: 5vh;
        height: 80px;
        .tnj-logo {
            height: 10vh;
        }
    }
}
