.tnj-cookie-banneer {
    // width: calc(100vw - 80px);
    position: fixed;
    bottom: -100%;
    left: 40px;
    right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 28px 40px;
    z-index: 1000;
    box-sizing: border-box;
    background-color: white;
    border-radius: 12px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    transition-duration: 0.4s;
    transition-timing-function: ease-out;

    &.show {
        animation-duration: 0.6s;
        animation-name: slideinCookie;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        @keyframes slideinCookie {
            from {
                bottom: -100%;
            }

            to {
                bottom: 40px;
            }
        }
    }
    button {
        min-width: 10vw;
        flex-shrink: 0;
        margin-left: 16px;
        font: var(--title-3);
        // font-size: 20px !important;
        font-weight: 600 !important;
    }
    span {
        color: var(--false-black); //black
        h6 {
            font: var(--text-medium);
            // font-size: 18px !important;
            letter-spacing: 0.5px;
            line-height: 20px !important;
            font-weight: 700 !important;
            margin: 0px !important;
        }
        p {
            font: var(--text);
            font-weight: 300 !important;
            // font-size: 14px;
            line-height: 1.43;
            letter-spacing: 0.3px;
            margin: 8px 0;
            strong {
                font-weight: 500;
            }
            a,
            a:active,
            a:visited,
            a:hover {
                color: var(--false-black); //black
            }
        }
    }
}
@media screen and (max-width: 640px) {
    .tnj-cookie-banneer {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 8px;
        left: 20px;
        right: 20px;
        padding: 24px;
        &.show {
            animation-duration: 0.6s;
            animation-name: slideinCookieMobile;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
            @keyframes slideinCookieMobile {
                from {
                    bottom: -100%;
                }
    
                to {
                    bottom: 120px;
                }
            }
        }
        span {
            grid-area: unset;
            margin-bottom: 16px;
        }
        button {
            width: 100%;
            padding: 8px 32px;
            font-weight: 600 !important;
        }
    }
}
