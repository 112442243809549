.rubber-front {
    position: absolute;
    background-color: var(--green-enjoy);
    box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.2);
    color: white;
    font-weight: 800;
    z-index: 3;
    top: -16px;
    right: -16px;
    padding: 12px 32px;
    border-radius: 8px;
    border-bottom-right-radius: 0;
}
.rubber-back {
    background-color: #7d922b;
    z-index: 1;
    position: absolute;
    transform: rotate(45deg);
    right: -11px;
    top: 15px;
    width: 24px;
    height: 24px;
}
@media (max-width: 450px), (max-height: 640px) {
    .rubber-front,
    .rubber-back {
        box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.2);
        font-weight: 700;
        font-size: 14px;
        padding: 7px 14px;
    }
    .rubber-front {
        border-radius: 4px;
        border-bottom-right-radius: 0;
    }
}
