.arrows-component {
    display: flex;
    align-items: center;
    height: auto;
    > .arrow:first-of-type {
        margin-right: 24px;
    }
}

.arrow {
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
        margin: 10px;
        width: 28px;
        height: 28px;
        border: none;
        fill: var(--green-enjoy);
        // border-left: 3.5px solid var(--green-enjoy);
        outline: none;
        background: none;
        transition-duration: 0.2s;
        &.small {
            width: 16px;
            height: 16px;
            fill: #1c1c1c;
            // border-left: 2.5px solid #1c1c1c;
        }
        &.disabled {
            fill: grey;
        }
        &.left {
            transform: scaleX(-1);
        }
    }
    &:hover {
        > svg:not(.disabled) {
            &.left {
                transform: scaleX(-1.5) scaleY(1.5);
            }
            &.right {
                transform: scale(1.5);
            }
            transition-duration: 0.2s;
        }
    }
}
