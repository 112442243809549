@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.popup-content.login-popup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 109px;
    background-color: var(--bg-color);
    max-width: 800px;
    width: 80vw;
    min-height: 500px;
    // max-height: 588px;
    // height: 80vw;
    border-radius: 30px;
    overflow: hidden;
    color: var(--false-black);
    position: absolute;
    --login-padding: 48px;
    .link {
        color: var(--green-enjoy);
        cursor: pointer;
    }
    .upperbar {
        display: none;
    }
    .login-close {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 20px;
        padding: 4px;
        height: 20px;
        z-index: 2;
        transition-duration: 0.2s;
        &:hover {
            transform: scale(0.8);
            transition-duration: 0.2s;
        }
    }
    .login-bg {
        grid-row: 1/3;
        grid-column: 2/3;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(183, 205, 0, 0.9), rgba(183, 205, 0, 0.9)), url(../../assets/nice.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 0;
    }
    .top-row {
        padding: 0 48px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        grid-row: 1/2;
        z-index: 1;
        &.right {
            grid-column: 2 / 3;
            color: white;
            font-family: "Inter", sans-serif;
            .quote {
                width: 24px;
                margin: 56px 0 18px;
            }
            p {
                font: var(--text-high);
                font-weight: 400;
                line-height: 23px;
                margin: 0 0 24px;
            }
            .author {
                h3 {
                    margin: 0 0 8px;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 21, 78px;
                }
                h4 {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 16, 94px;
                }
            }
        }
        &.left {
            grid-column: 1 / 2;
            h2 {
                margin: 32px 0 10px;
                font: var(--title-2);
                font-size: 30px;
            }
            p {
                padding-bottom: 16px;
                margin: 0 0 25px;
                color: #8692a6;
                border-bottom: 1px solid #f5f5f5;
                font-size: 18px;
                line-height: 28px;
            }
            span {
                font: var(--text);
                margin-bottom: 16px;
                &.forgot {
                    text-align: right;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .checkboxSpan {
                label {
                    cursor: default;
                }
                input {
                    cursor: pointer;
                }
            }
            .reset-response {
                p {
                    padding-bottom: 0;
                    border-bottom: none;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 157.69%;
                    margin-bottom: 0px;
                    &.highlighted-reset-paragraph {
                        color: var(--green-enjoy);
                        font-weight: 500;
                    }
                }
            }
        }
        .input,
        .db-input {
            width: 100%;
            height: 60px;
            padding: 0;
            margin: 0 0 28px;
            input {
                padding: 0 0 0 16px;
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                box-sizing: border-box;
            }
        }
        .input {
            border: 1px solid #ddd;
            border-radius: 6px;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            &:last-of-type {
                margin-bottom: 16px;
            }
            // img {
            //     position: absolute;
            //     right: 8px;
            //     padding: 8px;
            //     border-radius: 6px;
            //     &:active {
            //         background-color: lightgrey;
            //     }
            // }
        }
        .db-input {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
            input {
                border: 1px solid #ddd;
                border-radius: 6px;
            }
        }
    }
    .bottom-row {
        padding: 0 48px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        grid-row: 2/3;
        font: var(--text);
        z-index: 1;
        .action-button {
            width: 100%;
            height: 60px;
            font: var(--text-high);
            font-weight: 600 !important;
            padding: 0;
        }
        &.left {
            grid-column: 1 / 2;
        }
        &.right {
            // display: none;
            grid-column: 2 / 3;
            .action-button {
                border-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                .fb-login-icon {
                    margin: 0 20px 0 0;
                    height: 20px;
                    width: 20px;
                }
                span {
                    font-family: "Inter", sans-serif;
                    color: var(--false-black);
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    margin: 0;
                }
                &:active {
                    span {
                        color: white;
                    }
                }
            }
        }
        span {
            text-align: center;
            margin-top: 8px;
            .link {
                color: var(--green-enjoy);
                cursor: pointer;
            }
        }
    }
}

.error-message {
    color: red;
}
@media screen and (max-width: 640px) {
    .popup-content.login-popup {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 109px 109px;
        .login-close {
            background-color: var(--green-enjoy);
            padding: 3px;
            // box-sizing: border-box;
            border-radius: 50%;
        }
        .login-bg,
        .top-row.right {
            display: none;
        }
        .top-row {
            &.left {
            }
        }
        .bottom-row {
            &.left {
                // grid-row: 3/4;
            }
            &.right {
                grid-column: 1/2;
                grid-row: 3/4;
                button {
                    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0625);
                }
                // padding: 0;
            }
        }
    }
}
@media screen and (max-width: 450px) {
    .popup-overlay.login-popup {
        background-color: var(--bg-color);
        .popup-content {
            padding: 80px 0 56px;
            min-width: unset;
            max-width: unset;
            width: 100vw;
            height: 100vh;
            // overflow: scroll;
            position: relative;
            box-sizing: border-box;
            border-radius: 0;
            grid-template-rows: none;
            .login-close {
                display: none;
            }
            .upperbar {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                padding: 0 var(--login-padding);
                .back-arrow {
                    height: 16px;
                    padding: 0 24px 24px 0;
                }
                .login-tnj-logo {
                    width: 80px;
                }
            }
            .top-row {
                grid-row: 2/3;
            }
            .bottom-row {
                grid-row: 3/4;
                grid-row: unset;
            }
        }
    }
}
@media (max-width: 450px) and (max-height: 780px) {
    .popup-overlay.login-popup {
        .popup-content {
            overflow-y: scroll;
            padding: 6vh 0 8.4vh;
            --login-padding: 36px;
            .upperbar .login-tnj-logo {
                width: 64px;
            }
            .top-row.left {
                h2 {
                    margin-top: 8px;
                }
                p {
                    margin-bottom: 0;
                    border: none;
                }
                .input {
                    // margin-bottom: 8px;
                    // height: 40px;
                }
                span {
                    margin-bottom: 8px;
                }
            }
            .bottom-row span {
                margin-top: 12px;
            }
        }
    }
}
